import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
  faStar,
);

export default function EventAttendeeHost({ name }) {
  return (
    <>
      <li className="person-line">
        <ul className="person-grid">
          <li className="event-icon col-span-1">
            <FontAwesomeIcon icon="star" className="fa-icon" />
          </li>
          <li className="person-name">{name}</li>
        </ul>
      </li>
    </>
  )
}
