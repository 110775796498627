import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'property-information'; // https://github.com/remarkjs/react-markdown/issues/747
import EventBody from '../components/EventBody';
import EventDetails from '../components/EventDetails';
import EventTitle from '../components/EventTitle';
import EventAttendees from '../components/EventAttendees';
import RsvpOptions from '../components/RsvpOptions';

export default function Event() {
  const pageParams = useParams();
  // / event/eventid/anonauth
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [eventDetails, setEventDetails] = useState([])
  const [attendeeList, setAttendeeList] = useState([])
  const [eventId, setEventId] = useState([])

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    //get URL and URL params
    const url = new URL(document.location.href)
    let urlParams = new URLSearchParams(document.location.search)

    //set variables for viewer token and eventId as pulled from URL, URL params
    let vToken = urlParams.get('t');
    const urlEventId = pageParams.eventId;

    let baseUrl = process.env.BASE_URL;
    fetch(`${baseUrl}/api/v01/event/${urlEventId}/anonauth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vToken),
      credentials: 'include',
    }).then(authResponse =>
      fetch(`${baseUrl}/api/v01/event/${urlEventId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
        .then(getResponse => {
          if (getResponse.status == 200) {
            return getResponse.json();
          } else {
            return Promise.reject(getResponse);
          }
        })
        .then(setEventDetails)
        .catch(getErr => {
          console.log(getErr);
        })
        .finally(() => {
          setAttendeeList(() => {
            eventDetails.attendees
          });
          setEventId(() => {
            eventDetails.id
          });
          setIsLoading(false);
          setError(false);
        })
    );

    return () => {
      console.log('loaded')
      console.log('vtoken: ', vToken)
      console.log('urlEventId: ', urlEventId)
    }
  }, [])

  //consts
  const viewerId = eventDetails.selfEsuId
  let attendees = eventDetails.attendees
  let viewerDetails = []

  function getHostName(attendees) {
    // attendees =
    //   eventDetails.attendees !== undefined ? eventDetails.attendees : []
    let host = ''
    host = attendees.map((attendee) => {
      if (attendee.isHost) return attendee.name
    })
    return host
  }

  function getViewerName(attendees) {
    getViewerDetails(attendees)
    let viewer = []
    viewer = attendees.find((attendee) => {
      if (attendee.esuid === viewerId) return attendee.name
    })
    return viewer
  }

  function getViewerDetails(attendees) {
    viewerDetails = attendees.find((attendee) => {
      if (viewerId === attendee.esuid) return attendee
    })
    return viewerDetails
  }

  return (
    <div className="event-page">
      {isLoading ? (
        <p>loading...</p>)
        : (
      <>
        {/* {console.log(eventDetails)} */}
        <EventTitle
          title={eventDetails.title}
          host={getHostName(attendees)}
          eventId={eventId}
          startTime={eventDetails.startTime}
        />

        <main className="screen-padding py-4 flex flex-row flex-wrap-reverse md:flex-nowrap sm:gap-4 md:gap-8 lg:gap-16 ">
          <div className="w-full">
            <EventBody description={eventDetails.description} />
            <EventAttendees
              attendees={attendees}
              hostId={getHostName(attendees)}
              viewerId={getViewerName(attendees)}
            />
          </div>
          <div className="w-full md:max-w-1/4">
            <EventDetails
              locationName={eventDetails.location.name}
              locationAddress={eventDetails.location.address}
              startTime={eventDetails.startTime}
            />
            <div>
              <RsvpOptions
                attendees={attendees}
                viewerDetails={getViewerDetails(attendees)}
                setAttendeeList={setAttendeeList}
              />
            </div>
          </div>
        </main>
      </>)}
    </div>
  )
};