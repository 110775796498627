import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Event from "./pages/Event";
import Header from "./components/Header";
import Footer from "./components/Footer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />
  },
  {
    path: "/event/:eventId",
    element: <Event />
  }
]);

export default function App() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className='flex-none'>
        <Header />
      </div>
      <div className="grow">
        <RouterProvider router={router} />
      </div>
      <div className="flex-none">
        <Footer />
      </div>
    </div>
  );
}
