import React from "react";

export default function EventAttendee({ name }) {
  return (
    <>
      <li className="person-line">
        <ul className="person-grid">
          <li className="event-icon col-span-1" />
          <li className="person-name">{name}</li>
        </ul>
      </li>
    </>
  )
}
