import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
  faStar,
);

export default function EventTitle({ title, eventId, host, startTime }) {
  const eventDate = new Date(startTime).getTime()
  const todayNow = new Date().getTime()
  const milliToDaysConversion = 1000 * 3600 * 24
  const daysToEvent = Math.round((eventDate - todayNow) / milliToDaysConversion)

  return (
    <div className="w-full h-36 screen-padding py-4 bg-paper shadow-md flex-col justify-center items-start gap-2.5 inline-flex">
      {/* to autopopulate with event name */}
      <div>
        <h2 className="event-title text-2xl font-bold " key={eventId}>
          {title}
        </h2>
        <h3 className="my-1.5 px-1 text-sm text-center bg-darkhoney/20 rounded-xl ring-1 ring-darkhoney/60">
          {daysToEvent} days away
        </h3>
      </div>
      <div className="event-host-section">
        <div className="flex items-center">
          <span className="event-icon pr-2 ">
            <FontAwesomeIcon
              icon="star"
              className="bg-clip-content drop-shadow-pretty"
            />
          </span>
          {/* to autopopulate with organizer name(s) */}
          <p className="text-md">
            Hosted By <span className="text-lg font-medium">{host}</span>
          </p>
        </div>
      </div>
    </div>
  )
};
