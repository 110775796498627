import Markdown from 'react-markdown';

export default function EventBody({ description }) {
  return (
    <div>
      <h3 className="h3-detail">Details</h3>
      {/* autopopulate with details as markdown*/}
      <Markdown className="p-detail">{description}</Markdown>
    </div>
  )
}
