import React from 'react';

export default function Navbar() {
  return (
    <div className="layout-nav flex flex-around gap-2">
      <a href="/" className="navlink">
        home
      </a>
    </div>
  )
}
