import React from 'react';
import { Tab } from '@headlessui/react';
import EventAttendee from './EventAttendee';
import EventAttendeeHost from './EventAttendeeHost';
import EventAttendeeViewer from './EventAttendeeViewer';

const attending = 'Attending';
const declined = 'Absent';
const noResponse = 'NoResponse';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function EventAttendeesTable({ attendees, viewerId, hostId }) {
  return (
    <Tab.Group>
      <Tab.List className="flex flex-between space-x-1 rounded-xl bg-darkhoney/20 p-3.5">
        <Tab
          key={'attending'}
          className={({ selected }) =>
            classNames(
              'attendee-tab-label py-3',
              selected
                ? 'bg-darkhoney shadow-star'
                : 'text-blackish hover:bg-darkhoney/60 hover:text-blackish',
            )
          }
        >
          Attending
        </Tab>
        <Tab
          key={'invited'}
          className={({ selected }) =>
            classNames(
              'attendee-tab-label',
              selected
                ? 'bg-darkhoney shadow-star'
                : 'text-blackish hover:bg-darkhoney/60 hover:text-blackish',
            )
          }
        >
          Invited
        </Tab>
        <Tab
          key={'declined'}
          className={({ selected }) =>
            classNames(
              'attendee-tab-label',
              selected
                ? 'bg-darkhoney shadow-star '
                : 'text-blackish hover:bg-darkhoney/60 hover:text-blackish',
            )
          }
        >
          Declined
        </Tab>
      </Tab.List>
      <Tab.Panels>
        {/* attending panel */}
        <Tab.Panel className="w-full">
          <ul>
            {attendees.map((attendee) => {
              if (
                attendee.rsvp == attending &&
                attendee.esuid == viewerId.esuid
              )
                return (
                  <EventAttendeeViewer
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == attending && attendee?.isHost == true)
                return (
                  <EventAttendeeHost
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == attending && attendee.name !== hostId)
                return (
                  <EventAttendee key={attendee.esuid} name={attendee.name} />
                )
            })}
          </ul>
        </Tab.Panel>
        {/* invited */}
        <Tab.Panel data-no-reponse>
          <ul>
            {attendees.map((attendee) => {
              if (
                attendee.rsvp == noResponse &&
                attendee.esuid == viewerId.esuid
              )
                return (
                  <EventAttendeeViewer
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == noResponse && attendee.name == hostId)
                return (
                  <EventAttendeeHost
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == noResponse && attendee.name !== hostId)
                return (
                  <EventAttendee key={attendee.esuid} name={attendee.name} />
                )
            })}
          </ul>
        </Tab.Panel>

        <Tab.Panel data-declined>
          <ul>
            {attendees.map((attendee) => {
              if (attendee.rsvp == declined && attendee.esuid == viewerId.esuid)
                return (
                  <EventAttendeeViewer
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == declined && attendee.name == hostId)
                return (
                  <EventAttendeeHost
                    key={attendee.esuid}
                    name={attendee.name}
                  />
                )
              else if (attendee.rsvp == declined && attendee.name !== hostId)
                return (
                  <EventAttendee key={attendee.esuid} name={attendee.name} />
                )
            })}
          </ul>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
};
