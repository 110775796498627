import React from 'react';
import BannerReverse from './BannerReverse';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLocationDot,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
  faClock,
  faLocationDot,
);

export default function EventDetails({ startTime, locationName, locationAddress }) {
  const eventDateTime = new Date(startTime).toUTCString()
  //substring includes date
  const eventDate = eventDateTime.substring(0, 16)
  //substring includes time
  const eventTime = eventDateTime.substring(17)

  return (
    <div className="event-details">
      <BannerReverse>
        <div className="event-date-time">
          <span className="event-icon">
            <FontAwesomeIcon
              icon="clock"
              className="bg-clip-content drop-shadow-pretty"
            />
          </span>
          {/* date and time */}
          <div className="w-7/8 xl:1/2">
            <p className="mb-2">
              {eventDate}
              <br />
              {eventTime}
            </p>
            {/* <p>Add to Calendar</p> */}
          </div>
        </div>
        <div className="event-location w-full">
          <span className="event-icon">
            <FontAwesomeIcon
              icon="location-dot"
              className="bg-clip-content drop-shadow-pretty"
            />
          </span>
          {/* pull in location */}
          {/* ADD VALIDATION - IF NO NAME, JUST PHYSICAL ADDRESS ETC. */}
          <div className="flex-1 divide-y divide-solid divide-charcoal/60">
            <div>
              <p>{locationName}</p>
              <a
                className="text-charcoal/60"
                href={`https://www.google.com/search?q=${locationAddress}`}
              >
                {locationAddress}
              </a>
            </div>
          </div>
        </div>
      </BannerReverse>
    </div>
  )
}
