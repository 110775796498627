import '../layouts.css'

export default function Footer() {
  return (
    <div className="layout-footer">
      <h6>
        <span className='text-bolder'>
          made for people
        </span>
      </h6>
    </div>
  )
}
