import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(
  faPenToSquare,
)

export default function EventAttendeeViewer({ name }) {
  let [isOpen, setIsOpen] = useState(false)
  function closeNameModal() {
    setIsOpen(false)
  }
  function openNameModal() {
    setIsOpen(true)
  }

  return (
    <>
      <li className="person-line">
        <ul className="person-grid">
          {/* icon to edit name */}
          <li className="event-icon col-span-1" onClick={openNameModal}>
            <FontAwesomeIcon icon="pen-to-square" className="fa-icon" />
          </li>
          {/* MODAL FOR NAME CHANGE */}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeNameModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0  bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto bg-greenblack bg-opacity-30 backgrop-blur">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-trbr bg-paper p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-blackish"
                      >
                        Edit Name
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Future Name Change Modal
                        </p>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-lighthoney bg-darkhoney/20 px-4 py-2 text-sm font-medium text-blackish hover:bg-darkhoney/60 focus:outline-darkhoney focus-visible:ring-2 focus-visible:ring-darkhoney focus:shadow-star focus-visible:ring-offset-2"
                          onClick={closeNameModal}
                        >
                          Got it, thanks!
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* person name*/}
          <li className="person-name">{name}</li>
        </ul>
      </li>
    </>
  )
};
