import React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { Tab, Dialog, Transition } from '@headlessui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faCheck)

export default function RsvpOptions({
  attendees,
  viewerDetails,
  setAttendeeList
}) {
  const [value, setValue] = useState('')
  const [rsvp, setRSVP] = useState(viewerDetails.rsvp)
  let [isOpen, setIsOpen] = useState(false)

  const rsvpMap = new Map([
    [0, 'Attending'],
    [1, 'NoResponse'],
    [2, 'Absent'],
  ])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  //gets viewer's rsvp on load
  function getIndex(viewerDetails) {
    let rsvpIndex = rsvpMap.get(viewerDetails.rsvp)
    return rsvpIndex
  }
  //gets viewer's rsvp on change, adds to attendees array
  function submitViewerRsvp(index, currentValue) {
    let newRSVP = rsvpMap.get(index)
    setValue((currentValue) => {
      return index
    })
    setRSVP(newRSVP)

    //change viewer details to new RSVP
    viewerDetails = { ...viewerDetails, rsvp: newRSVP }

    //update attendees
    attendees.forEach((attendee) => {
      if (attendee.name !== viewerDetails.name) return
      else return (attendee.rsvp = viewerDetails.rsvp)
    })
    //update attendeeList
    setAttendeeList(attendees)

    //get URL and URL params
    const url = new URL(document.location.href)
    //set variable eventId as pulled from URL
    let urlEventId = url.pathname.slice(7)
    console.log(`URL event id: ${urlEventId}`)

    let baseUrl = process.env.BASE_URL;
    fetch(`${baseUrl}/api/v01/event/${urlEventId}/self/`, {
      method: 'PATCH',
      // credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rsvp: viewerDetails.rsvp
      }),
      credentials: 'include',
    })
      .then((res) => {
        if (res.status == 200) {
          return res.json()
        } else {
          return Promise.reject(res)
        }
      })
      .then(console.log('success'))
      .catch((e) => {
        console.log(e)
      })
  }

  function closeRSVPModal() {
    setIsOpen(false)
  }
  function openRSVPModal() {
    setIsOpen(true)
  }
  //use current Attendees to make attendee section reflect rsvp
  function updateAttendeeList(attendees) {
    setAttendeeList((currentAttendees) => [...currentAttendees, attendees])
  }

  return (
    <div className="my-4">
      <Tab.Group
        defaultIndex={getIndex(viewerDetails)}
        onChange={submitViewerRsvp}
        onClick={updateAttendeeList}
      >
        <Tab.List className="flex flex-between space-x-1 rounded-xl bg-greengold/20 p-1">
          {/* attending button */}
          <Tab
            key={'attending'}
            onClick={openRSVPModal}
            className={({ selected }) =>
              classNames(
                'attendee-tab-label py-2 rounded-lg',
                selected
                  ? 'bg-greengold shadow-star border border-greengold/20 text-lighthoney'
                  : 'text-blackish hover:bg-greengold/60 hover:text-blackish no-checkmark',
              )
            }
          >
            <span className="event-icon pr-1">
              <FontAwesomeIcon icon="check" className="fa-icon" />
            </span>
            Attending
          </Tab>
          {/* MODAL FOR NAME CHANGE */}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeRSVPModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0  bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto bg-greenblack bg-opacity-30 backgrop-blur">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-trbr bg-paper p-6 text-left align-middle shadow-xl transition-all grid">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-blackish w-full"
                      >
                        You've RSVPed!
                      </Dialog.Title>

                      {/* want to have "attending", "maybe", "decline" instead of the api vocab */}
                      {/* <div className="mt-2 w-full">
                        <p className="text-sm text-gray-500"></p>
                      </div> */}

                      <div className="mt-4 justify-self-end">
                        <button
                          type="button"
                          className="inline-flex rounded-md border border-lighthoney bg-darkhoney/20 px-4 py-2 text-sm font-medium text-blackish hover:bg-darkhoney/60 focus:outline-darkhoney focus-visible:ring-2 focus-visible:ring-darkhoney focus:shadow-star focus-visible:ring-offset-2"
                          onClick={closeRSVPModal}
                        >
                          Got it, thanks!
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          {/* maybe button */}
          <Tab
            key={'invited'}
            onClick={openRSVPModal}
            className={({ selected }) =>
              classNames(
                'attendee-tab-label py-2 rounded-lg',
                selected
                  ? 'bg-greengold shadow-star border border-greengold/20 text-lighthoney'
                  : 'text-blackish hover:bg-greengold/60 hover:text-blackish no-checkmark',
              )
            }
          >
            <span className="event-icon pr-1">
              <FontAwesomeIcon icon="check" className="fa-icon" />
            </span>
            Maybe
          </Tab>
          {/* decline button */}
          <Tab
            key={'declined'}
            onClick={openRSVPModal}
            className={({ selected }) =>
              classNames(
                'attendee-tab-label py-2 rounded-lg',
                selected
                  ? 'bg-greengold shadow-star border border-greengold/20 text-lighthoney'
                  : 'text-blackish hover:bg-greengold/60 hover:text-blackish no-checkmark',
              )
            }
          >
            <span className="event-icon pr-1">
              <FontAwesomeIcon icon="check" className="fa-icon" />
            </span>
            Declined
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  )
};
