import EventAttendeesTable from './eventAttendeesTable';

export default function EventAttendees({ attendees, viewerId, hostId }) {
  return (
    <>
      <h3 className="h3-detail">Attendees</h3>
      {/* autopopulates with details*/}
      <EventAttendeesTable
        attendees={attendees}
        viewerId={viewerId}
        hostId={hostId}
      ></EventAttendeesTable>
    </>
  )
};
