import React from "react";
import { useRouteError } from "react-router-dom";
import Banner from "../components/banner";

export default function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <main className=" md:p-24 flex flex-col align-center items-center">
      <div className='md:w-1/2 mt-8'>
        <Banner className='w-full home-banner'>
          <h2 className="text-center text-2xl font-bolder py-8 w-full">404</h2>
        </Banner>
        
        <div className='p-8 py-8 flex flex-col gap-4 justify-center'>
          <h3 className='text-xl'>It looks like the event you are looking for either does not exist, or you do not have permission to access it.</h3>
        </div>
      </div>
    </main>
  );
}
