import React from 'react';
import Banner from '../components/banner';

export default function Home() {
  return (
    <main className="md:p-24 flex flex-col align-center items-center">
      <div className="md:w-1/2 mt-8">
        <Banner>
          <>
            <h2 className="text-center text-2xl font-bolder py-8 w-full">
              Hey, you found us.
            </h2>
          </>
        </Banner>

        <div className="p-8 py-8 flex flex-col gap-4 justify-center">
          <h3 className="text-xl">
            This project is still in early development stages.
          </h3>

          <h3 className="text-xl">
            If you are interested in learning more, please reach out at&nbsp;
            <span className="text-bolder">
              hello &#91;at&#93; gatherr.net
            </span>
          </h3>
        </div>
      </div>
    </main>
  );
}
