import React from 'react';
import Navbar from './Navbar';
import '../layouts.css';

export default function Header() {
  return (
    <div className="layout-header">
      <h1 className="text-center text-paper text-[36px] leading-[44px] font-medium px-8 md:px-0 md:text-[57px] md:leading-[64px]">
        gatherr
      </h1>
      <Navbar />
    </div>
  )
}
